<div
  style="
    background-color: white;
    max-width: max-content;
    width: 100%;
    max-height: max-content;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  "
>
  <div style="width: 100%; height: 100%; padding: 48px">
    <h2>Glömt lösenord?</h2>
    <p style="font-weight: 500; font-size: 16px">
      Ingen fara! Ange din e-postadress så skickar vi en återställningslänk
      direkt.
    </p>
    <div style="height: 32px"></div>
    <mat-form-field style="width: 100%">
      <mat-label>Ange din mailadress</mat-label>
      <input
        matInput
        placeholder="mail@exempel.com"
        [formControl]="email"
        (blur)="updateEmailErrorMessage()"
        required
        type="email"
      />
      @if (email.invalid) {
      <mat-error>{{ emailErrorMessage() }}</mat-error>
      }
    </mat-form-field>
    <div style="height: 32px"></div>
    <button
      mat-flat-button
      color="primary"
      style="width: 100%; border-radius: 100px; font-size: 16px"
      (click)="requestPasswordReset()"
      [disabled]="email.value === '' || email.invalid || (showSpinner$ | async)"
    >
      Skicka länk
    </button>
  </div>
  <mat-progress-bar
    *ngIf="showSpinner$ | async"
    mode="indeterminate"
  ></mat-progress-bar>
</div>
<div
  *ngIf="showErrorMessage$ | async"
  style="
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;
  "
>
  <img
    src="../../../assets/icons/alert-circle-red.svg"
    style="margin-right: 8px"
  />
  <p style="font-weight: 400; font-size: 16px; color: #db6d69">
    {{ errorMessage$ | async }}
  </p>
</div>
<div
  *ngIf="showSuccessMessage$ | async"
  style="
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;
  "
>
  <img src="../../../assets/icons/info.svg" style="margin-right: 8px" />
  <p style="font-weight: 400; font-size: 16px; color: #3c79a5">
    En återställningslänk har skickats till din e-postadress!
  </p>
</div>
<div style="height: 24px"></div>
<div
  style="
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  "
>
  <p style="font-weight: 500; font-size: 16px; margin-right: 4px">
    Har du fått en länk?
  </p>
  <button
    mat-button
    (click)="goToLogin()"
    style="
      font-weight: 500;
      font-size: 16px;
      font-family: Work Sans;
      letter-spacing: 0px;
    "
  >
    Logga in igen
  </button>
</div>
