<div
  style="
    background-color: white;
    max-width: max-content;
    width: 100%;
    max-height: max-content;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  "
>
  <div style="width: 100%; height: 100%; padding: 48px">
    <h2>Skapa konto för att komma igång!</h2>
    <p style="font-weight: 500; font-size: 16px">
      Ange e-post och lösenord för att fortsätta.
    </p>
    <div style="height: 32px"></div>
    <mat-form-field style="width: 100%">
      <mat-label>Ange din mailadress</mat-label>
      <input
        matInput
        placeholder="mail@exempel.com"
        [formControl]="email"
        (blur)="updateEmailErrorMessage()"
        (keydown.enter)="checkBoxValue ? register() : null"
        required
        type="email"
      />
      @if (email.invalid) {
      <mat-error>{{ emailErrorMessage() }}</mat-error>
      }
    </mat-form-field>
    <mat-form-field style="width: 100%">
      <mat-label>Ange ditt lösenord</mat-label>
      <input
        matInput
        placeholder="Lösenord"
        [formControl]="password"
        (blur)="updatePasswordErrorMessage()"
        (keydown.enter)="checkBoxValue ? register() : null"
        required
        [type]="hide() ? 'password' : 'text'"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="clickEvent($event)"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide()"
      >
        <mat-icon>{{ hide() ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
      @if (password.invalid) {
      <mat-error>{{ passwordErrorMessage() }}</mat-error>
      }
    </mat-form-field>
    <mat-checkbox
      id="acceptTerms"
      name="acceptTermsCheck"
      color="primary"
      (change)="onCheckBoxChange($event)"
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        "
      >
        <p style="font-weight: 500; font-size: 16px; margin-right: 4px">
          Jag har läst och accepterar
        </p>
        <button
          mat-button
          (click)="goToPolicies()"
          style="
            font-weight: 500;
            font-size: 16px;
            font-family: Work Sans;
            letter-spacing: 0px;
            text-decoration: underline;
            padding: 0;
          "
        >
          Allmänna villkor
        </button>
      </div>
    </mat-checkbox>
    <div style="height: 32px"></div>
    <button
      mat-flat-button
      color="primary"
      style="width: 100%; border-radius: 100px; font-size: 16px"
      (click)="register()"
      [disabled]="!checkBoxValue || (showSpinner$ | async)"
    >
      Fortsätt
    </button>
  </div>
  <mat-progress-bar
    *ngIf="showSpinner$ | async"
    mode="indeterminate"
  ></mat-progress-bar>
</div>
<div
  style="
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;
  "
>
  <img
    *ngIf="showErrorMessage$ | async"
    src="../../../assets/icons/alert-circle-red.svg"
    style="margin-right: 8px"
  />
  <p style="font-weight: 400; font-size: 16px; color: #db6d69">
    {{ errorMessage$ | async }}
  </p>
</div>
<div style="height: 24px"></div>
<div
  style="
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  "
>
  <p style="font-weight: 500; font-size: 16px; margin-right: 4px">
    Har du redan ett konto?
  </p>
  <button
    mat-button
    (click)="goToLogin()"
    style="
      font-weight: 500;
      font-size: 16px;
      font-family: Work Sans;
      letter-spacing: 0px;
    "
  >
    Logga in
  </button>
</div>
