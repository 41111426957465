import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';

import { ApiService } from '../api/api.service';
import { CheckIfUserExistsResponseDto } from '../models/CheckIfUserExistsResponseDto';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailDealUserResolver {
  constructor(private api: ApiService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<CheckIfUserExistsResponseDto> {
    console.log('Route param: ', route.queryParamMap.get('email'));
    return this.api.checkIfUserExistsAndHasPlus(
      route.queryParamMap.get('email') as string
    );
  }
}

export const emailDealUserResolver: ResolveFn<boolean> = (route, state) => {
  return true;
};
