import { Injectable, inject } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SharedSnackbarComponent } from '../components/shared-snackbar/shared-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private _snackBar = inject(MatSnackBar);
  constructor() {}

  openSnackBar(
    message: string,
    durationSeconds: number,
    type: 'info' | 'error'
  ) {
    this._snackBar.openFromComponent(SharedSnackbarComponent, {
      data: {
        message,
        type,
      },
      duration: durationSeconds * 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
}
