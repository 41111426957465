import { CommonModule } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-shared-snackbar',
  standalone: true,
  imports: [
    MatSnackBarAction,
    MatSnackBarActions,
    MatSnackBarLabel,
    CommonModule,
  ],
  templateUrl: './shared-snackbar.component.html',
  styleUrl: './shared-snackbar.component.scss',
})
export class SharedSnackbarComponent {
  snackBarRef = inject(MatSnackBarRef);
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { message: string; type: 'info' | 'error' }
  ) {}
}
