import { BehaviorSubject, merge } from 'rxjs';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';

import { ApiService } from '../../api/api.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { jwtDecode } from 'jwt-decode';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    CommonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  showErrorMessage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  errorMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public email = new FormControl('', [Validators.required, Validators.email]);
  public password = new FormControl('', [Validators.required]);

  emailErrorMessage = signal('');
  passwordErrorMessage = signal('');
  hide = signal(true);
  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }

  constructor(private api: ApiService, private router: Router) {
    merge(this.email.statusChanges, this.email.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateEmailErrorMessage());
    merge(this.password.statusChanges, this.password.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updatePasswordErrorMessage());
  }

  updateEmailErrorMessage() {
    if (this.email.hasError('required')) {
      this.emailErrorMessage.set('Du måste ange en mailadress');
    } else if (this.email.hasError('email')) {
      this.emailErrorMessage.set('Inte en giltig mailadress');
    } else {
      this.emailErrorMessage.set('');
    }
  }
  updatePasswordErrorMessage() {
    if (this.password.hasError('required')) {
      this.passwordErrorMessage.set('Du måste ange ditt lösenord');
    } else {
      this.passwordErrorMessage.set('');
    }
  }

  goToForgotPassword() {
    this.router.navigate(['forgot-password']);
  }

  goToRegister() {
    this.router.navigate(['register']);
  }

  login() {
    this.showErrorMessage$.next(false);
    this.showSpinner$.next(true);
    if (this.email.value != null && this.password.value != null) {
      this.api.login(this.email.value, this.password.value, true).subscribe({
        next: (response) => {
          this.showSpinner$.next(false);
          console.log('Successful login!');
          localStorage.setItem('userToken', response.token);
          this.router.navigate(['manage-account']);
        },
        error: (error) => {
          this.showSpinner$.next(false);
          console.log('Error when logging in!');
          this.showErrorMessage$.next(true);
          const errorResponse = error.error as string;
          if (errorResponse === 'INVALID_PASSWORD') {
            this.errorMessage$.next('Felaktigt lösenord');
          } else if (errorResponse === 'NO_SUCH_USER') {
            this.errorMessage$.next(
              'Det finns inget konto skapat på denna mailadress.'
            );
          } else if (errorResponse === 'NO_PASSWORD_PROVIDED') {
            this.errorMessage$.next('Du måste ange ditt lösenord.');
          } else {
            this.errorMessage$.next(
              'Nånting gick fel. Vänligen vänta en stund och försök sedan igen.'
            );
          }
        },
        complete: () => {
          console.log('Login request completed.');
        },
      });
    }
  }
}
