import { EmailDealComponent } from './pages/email-deal/email-deal.component';
import { EmailDealUserResolver } from './resolvers/email-deal-user.resolver';
import { EmailPurchaseComponent } from './pages/email-purchase/email-purchase.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoggedInGuard } from './guards/logged-in.guard';
import { LoginComponent } from './pages/login/login.component';
import { ManageAccountComponent } from './pages/manage-account/manage-account.component';
import { ProductsResolver } from './resolvers/products-resolver.resolver';
import { RegisterAccountComponent } from './pages/register-account/register-account.component';
import { Routes } from '@angular/router';
import { SelectPaymentPlanComponent } from './pages/select-payment-plan/select-payment-plan.component';
import { SubscriptionInfoResolver } from './resolvers/subscription-info.resolver';
import { UserResolver } from './resolvers/user.resolver';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    resolve: () => {
      console.log('we are running the function');
      document.body.style.backgroundColor = '#eef8f4';
    },
    component: ManageAccountComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterAccountComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'manage-account',
    resolve: () => {
      document.body.style.backgroundColor = '#ffffff';
    },
    component: ManageAccountComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'select-payment',
    resolve: {
      productsDto: ProductsResolver,
      userDto: UserResolver,
      subscriptionDto: SubscriptionInfoResolver,
    },
    component: SelectPaymentPlanComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: 'email-deal',
    resolve: {
      productsDto: ProductsResolver,
      userExistsDto: EmailDealUserResolver,
    },
    component: EmailDealComponent,
  },
  {
    path: 'email-purchase',
    component: EmailPurchaseComponent,
  },
];
