<div class="snackBarContentContainer">
  <img
    [src]="
      data.type === 'info'
        ? '../../../assets/icons/info.svg'
        : '../../../assets/icons/alert-circle-red.svg'
    "
    style="margin-right: 8px"
  />
  <span
    [ngClass]="{
      labelTextInfo: data.type === 'info',
      labelTextError: data.type === 'error'
    }"
    matSnackBarLabel
  >
    {{ data.message }}
  </span>
</div>
