<div class="mainCard">
  <div class="mainCardInner">
    <ng-container *ngIf="route.queryParams | async as params">
      <ng-container *ngIf="params['state'] === 'complete'">
        <h2>Tack för ditt köp!</h2>
        <p class="bolderCardText">
          Ditt köp har genomförts! För att se detaljer om ditt köp och hantera
          ditt konto, logga in på ditt Wrebit-konto med e-postadressen:
        </p>
        <p class="cardText bolderCardText" *ngIf="params['email'] != ''">
          {{ params["email"] }}
        </p>
      </ng-container>
      <ng-container *ngIf="params['state'] === 'cancel'">
        <h2>Din session har gått ut</h2>
        <p class="bolderCardText">
          Klicka på länken du fick från Wrebit via mejl, för att ta del av
          kampanjen eller logga in för att fortsätta.
        </p>
      </ng-container>
      <button
        mat-flat-button
        color="primary"
        style="
          width: 100%;
          border-radius: 100px;
          font-size: 16px;
          margin-top: 32px;
        "
        (click)="goToLogin()"
      >
        Logga in
      </button>
    </ng-container>
  </div>
</div>
