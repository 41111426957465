import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';

import { ApiService } from '../api/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubscriptionInfo } from '../models/subscription.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionInfoResolver {
  constructor(private api: ApiService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<SubscriptionInfo> {
    return this.api.getUserSubscriptionInfo();
  }
}

export const subscriptionInfoResolver: ResolveFn<boolean> = (route, state) => {
  return true;
};
