import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { TokenPayload, User } from '../models/user.model';

import { ApiService } from '../api/api.service';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class UserResolver {
  constructor(public auth: AuthService, private api: ApiService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<User> {
    const tokenStr = localStorage.getItem('userToken') as string;
    const decoded = jwtDecode(tokenStr) as TokenPayload;
    return this.api.getUser(decoded.user);
  }
}

export const userResolver: ResolveFn<boolean> = (route, state) => {
  return true;
};
