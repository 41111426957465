import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Product, ProductResponse } from '../models/product.model';

import { ApiService } from '../api/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductsResolver {
  constructor(private api: ApiService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Product[]> {
    return this.api.fetchStripeProducts(route.queryParamMap.get("email"));
  }
}

export const productsResolverResolver: ResolveFn<boolean> = (route, state) => {
  return true;
};
