import { Token, TokenPayload } from '../models/user.model';

import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}
  getTokenPayload(token: Token): TokenPayload | null {
    if (!token) return null;
    const decoded = jwtDecode(token) as TokenPayload;
    return decoded;
  }

  isTokenValid(token: Token): boolean {
    if (!token) return false;
    const tokenPayload = this.getTokenPayload(token);
    if (!tokenPayload) {
      return false;
    } else {
      return tokenPayload.exp > Math.floor(Date.now() / 1000);
    }
  }
}
