import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'app-email-purchase',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MatRippleModule,
    MatButtonModule,
    CommonModule,
  ],
  templateUrl: './email-purchase.component.html',
  styleUrl: './email-purchase.component.scss',
})
export class EmailPurchaseComponent {
  userEmail: string = '';

  constructor(private router: Router, public route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      this.userEmail = params['email'];
    });
  }

  goToLogin() {
    this.router.navigate(['login']);
  }
}
