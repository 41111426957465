<mat-progress-bar
  *ngIf="!(firstTimeContentHasLoaded$ | async)"
  mode="indeterminate"
></mat-progress-bar>
<div class="backgroundCard">
  <ng-container *ngIf="firstTimeContentHasLoaded$ | async">
    <div class="headerContainer">
      <h2>Hantera abonnemang</h2>
      <div style="min-width: 50px; width: auto"></div>
      <mat-chip-set>
        <mat-chip
          *ngIf="
            hasActiveMobilePaidSubscription ||
            hasActiveWebRenewingPaidSubscription ||
            hasActiveWebCanceledPaidSubscription ||
            hasBoughtAnySubFromWeb ||
            hasBoughtAnySubFromMobile
          "
          [disabled]="false"
          class="chipStyle"
          [ngStyle]="{
            'background-color':
              hasActiveMobilePaidSubscription ||
              hasActiveWebRenewingPaidSubscription ||
              hasActiveWebCanceledPaidSubscription
                ? '#eaf6f1'
                : '#fcf0f0'
          }"
        >
          <p
            [ngClass]="{
          chipTextActive: (hasActiveMobilePaidSubscription || hasActiveWebRenewingPaidSubscription || hasActiveWebCanceledPaidSubscription),
          chipTextInactive: !(hasActiveMobilePaidSubscription || hasActiveWebRenewingPaidSubscription || hasActiveWebCanceledPaidSubscription),
        }"
          >
            {{
              !(
                hasActiveMobilePaidSubscription ||
                hasActiveWebRenewingPaidSubscription ||
                hasActiveWebCanceledPaidSubscription
              )
                ? "Inaktiv"
                : subscriptionDaysLeft < 15
                ? "Upphör snart"
                : "Aktiv"
            }}
          </p>
        </mat-chip>
      </mat-chip-set>
    </div>
    <mat-divider style="border-top-color: rgba(0, 0, 0, 1)"></mat-divider>
    <p class="subscriptionStatusText">
      {{
        !(
          hasActiveMobilePaidSubscription ||
          hasActiveWebRenewingPaidSubscription ||
          hasActiveWebCanceledPaidSubscription
        )
          ? "Din prenumeration är inaktiv."
          : "Din prenumeration är aktiv."
      }}
    </p>
    <ng-container [ngSwitch]="renderingMode$ | async">
      <ng-container
        *ngSwitchCase="renderingModeEnum.webActiveUser"
        [ngTemplateOutlet]="webActiveRenewingUser"
      ></ng-container>
      <ng-container
        *ngSwitchCase="renderingModeEnum.webCanceledStillActiveUser"
        [ngTemplateOutlet]="webCanceledStillActiveUser"
      ></ng-container>
      <ng-container
        *ngSwitchCase="renderingModeEnum.mobileActiveUser"
        [ngTemplateOutlet]="mobileActiveUser"
      ></ng-container>
      <ng-container
        *ngSwitchCase="renderingModeEnum.expiredUser"
        [ngTemplateOutlet]="expiredAccount"
      ></ng-container>
      <ng-container
        *ngSwitchCase="renderingModeEnum.firstTimeUser"
        [ngTemplateOutlet]="firstTimeUser"
      ></ng-container>
      <ng-container
        *ngSwitchDefault
        [ngTemplateOutlet]="loadingInfo"
      ></ng-container>
    </ng-container>
    <div style="height: 32px"></div>
    <button
      mat-button
      (click)="logOut()"
      style="
        width: 100%;
        height: 40px;
        border-radius: 100px;
        border: #54b88e;
        border-style: solid;
        border-width: thin;
        color: #54b88e;
      "
    >
      <p class="buttonText">Logga ut</p>
    </button>
  </ng-container>
</div>

<!-- ///// User has Wrebit+ VIA WEB ///// -->
<ng-template #webActiveRenewingUser>
  <img mat-card-image src="../../../assets/images/WrebitSplash.png" />
  <div style="height: 16px"></div>
  <div class="subscriptionInfoRow">
    <img
      src="../../../assets/icons/credit-card-black.svg"
      style="margin-right: 8px"
    />
    <p class="headerText">
      {{
        activeWebSubPlanBillingInterval === "month"
          ? displayRoundedPrice(activeWebSubPlanPriceOre / 100) +
            " kr exkl. moms per månad"
          : displayRoundedPrice(activeWebSubPlanPriceOre / 100) +
            " kr exkl. moms per år"
      }}
    </p>
  </div>
  <div style="height: 16px"></div>
  <div class="subscriptionInfoRow">
    <img
      src="../../../assets/icons/calendar-black.svg"
      style="margin-right: 8px"
    />
    <p class="headerText">
      {{ "Förnyas " + renewalDate }}
    </p>
  </div>
  <div style="height: 32px"></div>
  <div
    (click)="onClickHandleManageAccount()"
    class="custom-manage-account-card"
    matRipple
    matRippleColor="#43a87b22"
  >
    <div class="customManageAccountContainer">
      <div class="cardContent">
        <p class="cardHeader">Hantera ditt nuvarande abonnemang</p>
        <p class="cardBreadText">
          Granska eller hantera ditt abonnemang från ditt Stripe-konto.
        </p>
      </div>
      <div class="arrowContainer">
        <img
          mat-card-image
          src="../../../assets/icons/arrow-forward-black.svg"
        />
      </div>
    </div>
    <mat-progress-bar
      *ngIf="showSpinner$ | async"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>
  <div style="height: 32px"></div>
  <div class="mobileAppInfoContainer">
    <img
      mat-card-image
      src="../../../assets/images/wrebit-mobile-app.png"
      style="margin-right: 24px"
    />
    <div class="textAndLinksColumn">
      <h3>Ladda ner Wrebit</h3>
      <p class="cardBreadText">
        Ladda ner Wrebit-appen för att få full kontroll på din bokföring och
        fakturering direkt i mobilen.
      </p>
      <div style="height: 16px"></div>
      <div class="appstoreButtons">
        <img
          mat-card-image
          (click)="onClickIOS()"
          matRipple
          matRippleColor="#ffffff33"
          src="../../../assets/images/app-store.png"
          class="iosImage"
        />
        <img
          mat-card-image
          (click)="onClickAndroid()"
          matRipple
          matRippleColor="#ffffff33"
          src="../../../assets/images/google-play.png"
          class="androidImage"
        />
      </div>
    </div>
  </div>
</ng-template>

<!-- ///// User has CANCELED Wrebit+ VIA WEB _BUT_ still time left ///// -->
<ng-template #webCanceledStillActiveUser>
  <img mat-card-image src="../../../assets/images/WrebitSplash.png" />
  <div style="height: 16px"></div>
  <div class="subscriptionInfoRow">
    <img
      src="../../../assets/icons/credit-card-black.svg"
      style="margin-right: 8px"
    />
    <p class="headerText">
      {{
        activeWebSubPlanBillingInterval === "month"
          ? displayRoundedPrice(activeWebSubPlanPriceOre / 100) +
            " kr exkl. moms per månad"
          : displayRoundedPrice(activeWebSubPlanPriceOre / 100) +
            " kr exkl. moms per år"
      }}
    </p>
  </div>
  <div style="height: 16px"></div>
  <div class="subscriptionInfoRow">
    <img
      src="../../../assets/icons/calendar-red.svg"
      style="margin-right: 8px"
    />
    <div style="display: flex; flex-direction: column">
      <p class="redHeaderText">Du har avslutat ditt abonnemang</p>
      <p class="cardBreadText">
        {{ "Ditt abonnemang upphör den " + renewalDate }}
      </p>
    </div>
  </div>
  <div style="height: 32px"></div>
  <div
    (click)="onClickRenewAccount()"
    class="custom-card"
    matRipple
    matRippleColor="#43a87b22"
  >
    <div class="cardContent">
      <p class="cardHeader">Förnya ditt abonnemang</p>
      <p class="cardBreadText">
        Granska eller hantera ditt abonnemang från ditt Stripe-konto.
      </p>
    </div>
    <div class="arrowContainer">
      <img mat-card-image src="../../../assets/icons/arrow-forward-black.svg" />
    </div>
  </div>
</ng-template>

<!-- ///// User has Wrebit+ VIA MOBILE ///// -->
<ng-template #mobileActiveUser>
  <p *ngIf="subscriptionDaysLeft < 15" class="paidFromMobileText">
    Dina funktioner kommer att vara tillgängliga under resten av din
    faktureringsperiod.
  </p>
  <p class="paidFromMobileText">
    Det verkar som om du köpte denna prenumeration via Wrebit-appen. Du behöver
    hantera eller säga upp din prenumeration därifrån.
  </p>
</ng-template>

<!-- ///// Users subscription has EXPIRED (both WEB and MOBILE) ///// -->
<ng-template #expiredAccount>
  <img mat-card-image src="../../../assets/images/WrebitSplash.png" />
  <div style="height: 16px"></div>
  <div class="subscriptionInfoRow">
    <img
      src="../../../assets/icons/calendar-red.svg"
      style="margin-right: 8px"
    />
    <div style="display: flex; flex-direction: column">
      <p class="redHeaderText">Ditt abonnemang har upphört</p>
      <p class="cardBreadText">
        {{ "Ditt abonnemang upphörde den " + previousEndDate }}
      </p>
    </div>
  </div>
  <div style="height: 32px"></div>
  <div
    (click)="onClickRenewAccount()"
    class="custom-card"
    matRipple
    matRippleColor="#43a87b22"
  >
    <div class="cardContent">
      <p class="cardHeader">Förnya ditt abonnemang</p>
      <p class="cardBreadText">
        Granska eller hantera ditt abonnemang från ditt Stripe-konto.
      </p>
    </div>
    <div class="arrowContainer">
      <img mat-card-image src="../../../assets/icons/arrow-forward-black.svg" />
    </div>
  </div>
</ng-template>

<!-- ///// User has NO_ACTIVE_ACCOUNT and NO_PREVIOUS subscriptions (FIRST TIME USER) ///// -->
<ng-template #firstTimeUser>
  <img mat-card-image src="../../../assets/images/WrebitSplash.png" />
  <div style="height: 16px"></div>
  <div class="subscriptionInfoRow">
    <img
      src="../../../assets/icons/calendar-red.svg"
      style="margin-right: 8px"
    />
    <p class="redHeaderText">Du har inget aktivt abonnemang.</p>
  </div>
  <div style="height: 32px"></div>
  <div
    (click)="onClickRenewAccount()"
    class="custom-card"
    matRipple
    matRippleColor="#43a87b22"
  >
    <div class="cardContent">
      <p class="cardHeader">Uppgradera till Wrebit+</p>
      <p class="cardBreadText">
        Granska eller hantera ditt abonnemang från ditt Stripe-konto.
      </p>
    </div>
    <div class="arrowContainer">
      <img mat-card-image src="../../../assets/icons/arrow-forward-black.svg" />
    </div>
  </div>
</ng-template>

<!-- ///// DATA HAS NOT LOADED (Or error when loading data) ///// -->
<ng-template #loadingInfo></ng-template>
