export const environment = {
  production: false,
  version: '0.0.1',
  useTracing: false,
  api: {
    info: 'https://api.test.wrebit.helops.net',
    admin: 'https://api.test.wrebit.helops.net',
    bookkeep: 'https://api.test.wrebit.helops.net',
    chat: 'https://api.test.wrebit.helops.net',
    imageRead: 'https://fr-tf.wrebit.helops.net',
  },
};
