<div
  style="
    background-color: white;
    max-width: max-content;
    width: 100%;
    max-height: max-content;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  "
>
  <div style="width: 100%; height: 100%; padding: 48px">
    <h2>Välkommen!</h2>
    <p style="font-weight: 500; font-size: 16px">Ange e-post och lösenord.</p>
    <div style="height: 32px"></div>
    <mat-form-field style="width: 100%">
      <mat-label>Ange din mailadress</mat-label>
      <input
        matInput
        placeholder="mail@exempel.com"
        [formControl]="email"
        (blur)="updateEmailErrorMessage()"
        (keydown.enter)="login()"
        required
        type="email"
      />
      @if (email.invalid) {
      <mat-error>{{ emailErrorMessage() }}</mat-error>
      }
    </mat-form-field>
    <mat-form-field style="width: 100%">
      <mat-label>Ange ditt lösenord</mat-label>
      <input
        matInput
        placeholder="Lösenord"
        [formControl]="password"
        (blur)="updatePasswordErrorMessage()"
        (keydown.enter)="login()"
        required
        [type]="hide() ? 'password' : 'text'"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="clickEvent($event)"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide()"
      >
        <mat-icon>{{ hide() ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
      @if (password.invalid) {
      <mat-error>{{ passwordErrorMessage() }}</mat-error>
      }
    </mat-form-field>
    <button
      mat-button
      (click)="goToForgotPassword()"
      [disabled]="showSpinner$ | async"
      style="
        font-weight: 500;
        font-size: 16px;
        font-family: Work Sans;
        letter-spacing: 0px;
      "
    >
      Glömt lösenord
    </button>
    <div style="height: 32px"></div>
    <button
      mat-flat-button
      color="primary"
      style="width: 100%; border-radius: 100px; font-size: 16px"
      (click)="login()"
      [disabled]="showSpinner$ | async"
    >
      Logga in
    </button>
  </div>
  <mat-progress-bar
    *ngIf="showSpinner$ | async"
    mode="indeterminate"
  ></mat-progress-bar>
</div>
<div
  style="
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;
  "
>
  <img
    *ngIf="showErrorMessage$ | async"
    src="../../../assets/icons/alert-circle-red.svg"
    style="margin-right: 8px"
  />
  <p style="font-weight: 400; font-size: 16px; color: #db6d69">
    {{ errorMessage$ | async }}
  </p>
</div>
<div style="height: 24px"></div>
<div
  style="
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  "
>
  <p style="font-weight: 500; font-size: 16px; margin-right: 4px">
    Har du inget konto?
  </p>
  <button mat-button (click)="goToRegister()">Skapa nu</button>
</div>
