import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard {
  constructor(public auth: AuthService, private router: Router) {}
  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const tokenStr = localStorage.getItem('userToken');
    if (!tokenStr) {
      this.router.navigate(['login']);
    } else {
      if (!this.auth.isTokenValid(tokenStr)) {
        this.router.navigate(['login']);
      }
    }
  }
}
// export const loggedInGuard: CanActivateFn = (route, state) => {
//   const tokenStr = localStorage.getItem('userToken');
//     if (!tokenStr) {
//       return false;
//     } else {
//       return AuthService.isTokenValid(JSON.parse(tokenStr));
//     }
//   return true;
// };
