import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';

import { ApiService } from '../../api/api.service';
import { AuthService } from '../../services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { SnackbarService } from '../../services/snackbar.service';
import { SubscriptionInfo } from '../../models/subscription.model';

enum RenderingMode {
  loading,
  mobileActiveUser,
  webActiveUser,
  webCanceledStillActiveUser,
  expiredUser,
  firstTimeUser,
}

@Component({
  selector: 'app-manage-account',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MatDividerModule,
    MatChipsModule,
    MatCardModule,
    MatIconModule,
    MatRippleModule,
    MatButtonModule,
    MatProgressBarModule,
    CommonModule,
  ],
  templateUrl: './manage-account.component.html',
  styleUrl: './manage-account.component.scss',
})
export class ManageAccountComponent implements OnInit, OnDestroy {
  renderingMode$: BehaviorSubject<RenderingMode> =
    new BehaviorSubject<RenderingMode>(RenderingMode.loading);
  renderingModeEnum = RenderingMode;
  firstTimeContentHasLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  subscriptionDaysLeft: number = 0;
  renewalDate: string = '';
  previousEndDate: string = '';
  tokenPolling: any;
  hasBoughtAnySubFromMobile: boolean = false;
  hasBoughtAnySubFromWeb: boolean = false;
  hasActiveMobilePaidSubscription: boolean = false;
  hasActiveWebRenewingPaidSubscription: boolean = false;
  hasActiveWebCanceledPaidSubscription: boolean = false;
  activeWebSubPlanPriceOre: number = 0;
  activeWebSubPlanBillingInterval: 'year' | 'month' = 'year';
  hasCurrentSub: boolean = false;
  currentSubId: string = '';
  currentSubLevel: 'plus' | 'base' | 'undecided' = 'undecided';
  currentSubBoughtFromWeb: boolean = true;
  currentSubIsFreeTrial: boolean = false;
  currentSubValidUntil: string = '';

  constructor(
    private api: ApiService,
    private router: Router,
    public auth: AuthService,
    public snackService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.refreshUserToken();
    this.refreshSubscriptionData();
  }

  ngOnDestroy(): void {
    clearInterval(this.tokenPolling);
  }

  refreshSubscriptionData() {
    this.api.getUserSubscriptionInfo().subscribe({
      next: (response) => {
        this.setSubscriptionData(response);
        this.firstTimeContentHasLoaded$.next(true);
      },
      error: (error) => {
        console.log('Error when getting subscription data!');
        this.firstTimeContentHasLoaded$.next(true);
        this.snackService.openSnackBar(
          'Vi stötte på ett problem. Vänligen försök igen om en stund.',
          5,
          'error'
        );
      },
    });
  }

  displayRoundedPrice(price: number) {
    return Math.round(price);
  }

  setSubscriptionData(subscription: SubscriptionInfo) {
    this.hasBoughtAnySubFromMobile = subscription.hasBoughtAnySubFromMobile;
    this.hasBoughtAnySubFromWeb = subscription.hasBoughtAnySubFromWeb;
    this.hasActiveMobilePaidSubscription =
      subscription.hasActiveMobilePaidSubscription;
    this.hasActiveWebRenewingPaidSubscription =
      subscription.hasActiveWebRenewingPaidSubscription;
    this.hasActiveWebCanceledPaidSubscription =
      subscription.hasActiveWebCanceledPaidSubscription;
    this.activeWebSubPlanPriceOre = subscription.activeWebSubPlanPriceOre;
    this.activeWebSubPlanBillingInterval =
      subscription.activeWebSubPlanBillingInterval;
    this.hasCurrentSub = subscription.hasCurrentSub;
    if (subscription.hasCurrentSub) {
      const now = new Date();
      const subscriptionEnd = new Date(subscription.currentSubValidUntil);

      const diff = subscriptionEnd.getTime() - now.getTime();
      const days = Math.ceil(diff / (1000 * 3600 * 24));
      this.subscriptionDaysLeft = days;
      this.renewalDate = this.getMonth(subscriptionEnd);
      this.currentSubId = subscription.currentSubId;
      this.currentSubLevel = subscription.currentSubLevel;
      this.currentSubBoughtFromWeb = subscription.currentSubBoughtFromWeb;
      this.currentSubIsFreeTrial = subscription.currentSubIsFreeTrial;
      this.currentSubValidUntil = subscription.currentSubValidUntil;
    }
    if (
      (subscription.hasBoughtAnySubFromWeb ||
        subscription.hasBoughtAnySubFromMobile) &&
      subscription.recentmostPlusExpiredAt
    ) {
      const previousSubscriptionEnd = new Date(
        subscription.recentmostPlusExpiredAt
      );
      this.previousEndDate = this.getMonth(previousSubscriptionEnd);
    }

    switch (true) {
      case subscription.hasActiveWebRenewingPaidSubscription &&
        !subscription.hasActiveMobilePaidSubscription &&
        !subscription.hasActiveWebCanceledPaidSubscription:
        this.renderingMode$.next(RenderingMode.webActiveUser);
        break;
      case subscription.hasActiveWebCanceledPaidSubscription &&
        !subscription.hasActiveWebRenewingPaidSubscription &&
        !subscription.hasActiveMobilePaidSubscription:
        this.renderingMode$.next(RenderingMode.webCanceledStillActiveUser);
        break;
      case subscription.hasActiveMobilePaidSubscription &&
        !subscription.hasActiveWebRenewingPaidSubscription &&
        !subscription.hasActiveWebCanceledPaidSubscription:
        this.renderingMode$.next(RenderingMode.mobileActiveUser);
        break;
      case !subscription.hasActiveMobilePaidSubscription &&
        !subscription.hasActiveWebCanceledPaidSubscription &&
        !subscription.hasActiveWebRenewingPaidSubscription &&
        (subscription.hasBoughtAnySubFromMobile ||
          subscription.hasBoughtAnySubFromWeb):
        this.renderingMode$.next(RenderingMode.expiredUser);
        break;
      case !subscription.hasActiveMobilePaidSubscription &&
        !subscription.hasActiveWebCanceledPaidSubscription &&
        !subscription.hasActiveWebRenewingPaidSubscription &&
        !subscription.hasBoughtAnySubFromMobile &&
        !subscription.hasBoughtAnySubFromWeb:
        this.renderingMode$.next(RenderingMode.firstTimeUser);
        break;
      default:
        this.renderingMode$.next(RenderingMode.loading);
        break;
    }

    console.log('What is the renderingMode? ', this.renderingMode$.value);
  }

  getMonth(date: Date): string {
    const months: string[] = [
      'januari',
      'februari',
      'mars',
      'april',
      'maj',
      'juni',
      'juli',
      'augusti',
      'september',
      'oktober',
      'november',
      'december',
    ];
    return (
      date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear()
    );
  }

  refreshUserToken() {
    this.tokenPolling = setInterval(() => {
      console.log('Refreshing token...');
      this.api.refreshToken().subscribe({
        next: (reponse) => {
          this.refreshSubscriptionData();
        },
        error: (error) => {
          console.log('Fatal error! Could not refresh token!');
          clearInterval(this.tokenPolling);
          this.logOut();
        },
      });
    }, 180000);
  }

  onClickHandleManageAccount() {
    this.showSpinner$.next(true);
    this.api.sendUserPortalInitialization().subscribe({
      next: () => {
        console.log('Sending initialization to server successful');
      },
      error: () => {
        console.log('Error sending initialization to server');
      },
    });
    this.api.getManageSubscriptionLink().subscribe({
      next: (response) => {
        this.showSpinner$.next(false);
        window.open(response.url, '_self');
      },
      error: (error) => {
        this.showSpinner$.next(false);
        console.log('Error when fetching link for managing subscription!');
      },
      complete: () => {
        console.log('Get Manage Subscription Link request COMPLETE');
      },
    });
  }

  onClickRenewAccount() {
    this.router.navigate(['select-payment']);
  }

  onClickIOS() {
    window.open(
      'https://apps.apple.com/se/app/wrebit/id1046200849?platform=iphone'
    );
  }

  onClickAndroid() {
    window.open('https://play.google.com/store/apps/details?id=se.wrebit.app');
  }

  logOut() {
    localStorage.setItem('userToken', '');
    this.router.navigate(['login']);
  }
}
