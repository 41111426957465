import { HttpClient, HttpParams } from '@angular/common/http';
import { TokenResponse, User } from '../models/user.model';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environment/environment';
import { CheckIfUserExistsResponseDto } from '../models/CheckIfUserExistsResponseDto';
import { CheckoutResponse } from '../models/checkout-session.model';
import { Company } from '../models/company.model';
import { ManageAccountSubscription } from '../models/manage-subscription.model';
import { Product } from '../models/product.model';
import { SubscriptionInfo } from '../models/subscription.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  adminApi = environment.api.admin;
  infoApi = environment.api.info;
  bookkeepApi = environment.api.bookkeep;

  constructor(private http: HttpClient) {}

  /*///// USER API /////*/

  login(username: string, password: string): Observable<TokenResponse> {
    const fromWeb = true;
    return this.http.post<TokenResponse>(`${this.infoApi}/login`, {
      username,
      password,
      fromWeb,
    });
  }

  register(username: string, password: string): Observable<TokenResponse> {
    const fromWeb = true;
    return this.http.post<TokenResponse>(`${this.infoApi}/register`, {
      username,
      password,
      fromWeb,
    });
  }

  requestPasswordReset(email: string): Observable<string> {
    return this.http.post(
      `${this.infoApi}/request-password-reset?email=${email}`,
      {},
      {
        responseType: 'text',
      }
    );
  }

  createCompany(company: Company): Observable<Company> {
    return this.http.post<Company>(`${this.infoApi}/companies`, company);
  }

  refreshToken(): Observable<TokenResponse> {
    return this.http.get<TokenResponse>(`${this.infoApi}/token`);
  }

  getUser(userId: string): Observable<User> {
    return this.http.get<User>(`${this.infoApi}/users/${userId}`);
  }

  /*///// STRIPE API /////*/

  fetchStripeProducts(email: string | null): Observable<Product[]> {
    const queryParams = new HttpParams();
    if (!!email) {
      queryParams.set('email', email);
    }
    return this.http.get<Product[]>(
      `${this.infoApi}/stripe/products?email=${email}`,
      {
        params: queryParams,
      }
    );
  }

  startStripeCheckoutSession(priceId: string): Observable<CheckoutResponse> {
    return this.http.post<CheckoutResponse>(`${this.infoApi}/stripe/checkout`, {
      priceId,
    });
  }

  startStripeCheckoutSessionByEmail(
    priceId: string,
    email: string
  ): Observable<CheckoutResponse> {
    return this.http.post<CheckoutResponse>(
      `${this.infoApi}/stripe/checkout-by-email`,
      {
        priceId,
        email,
      }
    );
  }

  getManageSubscriptionLink(): Observable<ManageAccountSubscription> {
    return this.http.get<ManageAccountSubscription>(
      `${this.infoApi}/stripe/manage-subscription`
    );
  }

  getUserSubscriptionInfo(): Observable<SubscriptionInfo> {
    return this.http.get<SubscriptionInfo>(
      `${this.infoApi}/stripe/subscription-info-for-web`
    );
  }

  /*///// WEB TRACKING API /////*/

  sendUserCheckoutInitialization(): Observable<any> {
    return this.http.post<any>(
      `${this.infoApi}/web-tracking/stripe-checkout-initialized`,
      {}
    );
  }

  sendUserPortalInitialization(): Observable<any> {
    return this.http.post<any>(
      `${this.infoApi}/web-tracking/stripe-portal-initialized`,
      {}
    );
  }

  /*///// USER INFO API /////*/

  checkIfUserExistsAndHasPlus(
    email: string
  ): Observable<CheckIfUserExistsResponseDto> {
    console.log('checkIfUserExistsAndHasPlus email: ', email);
    const queryParams = new HttpParams();
    queryParams.set('email', email);
    return this.http.get<CheckIfUserExistsResponseDto>(
      `${this.infoApi}/stripe/check-if-user-exists-by-email?email=${email}`,
      { params: queryParams }
    );
  }
}
