import { BehaviorSubject, merge } from 'rxjs';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';

import { ApiService } from '../../api/api.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    CommonModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  showErrorMessage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  errorMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  showSuccessMessage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public email = new FormControl('', [Validators.required, Validators.email]);

  emailErrorMessage = signal('');
  hide = signal(true);
  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }

  constructor(private api: ApiService, private router: Router) {
    merge(this.email.statusChanges, this.email.valueChanges)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateEmailErrorMessage());
  }

  updateEmailErrorMessage() {
    if (this.email.hasError('required')) {
      this.emailErrorMessage.set('Du måste ange en mailadress');
    } else if (this.email.hasError('email')) {
      this.emailErrorMessage.set('Inte en giltig mailadress');
    } else {
      this.emailErrorMessage.set('');
    }
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  requestPasswordReset() {
    this.showSpinner$.next(true);
    this.showErrorMessage$.next(false);
    this.showSuccessMessage$.next(false);
    if (this.email.value != null) {
      this.api.requestPasswordReset(this.email.value).subscribe({
        next: (response) => {
          this.showSpinner$.next(false);
          console.log('Request password request successful!');
          this.showSuccessMessage$.next(true);
        },
        error: (error) => {
          this.showSpinner$.next(false);
          console.log('Error when requesting password reset.');
          this.showErrorMessage$.next(true);
          this.errorMessage$.next(
            'Användare kunde inte hittas. Vänligen skapa ett konto'
          );
        },
        complete: () => {
          console.log('Request password reset request completed.');
        },
      });
    }
  }
}
